/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import classNames from "classnames";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useFirstVisibleToUser } from "../hooks";
import { LocaleIcon, PlusIcon } from "../icons";
import Filter, { SelectOptions } from "./Filter";

interface LeverJob {
  id: string;
  name: string;
  text: string;
  categories: {
    location: string;
    team: string;
    department: string;
    commitment: string;
  };
  additionalPlain: string;
  hostedUrl: string;
  applyUrl: string;
  createdAt: number;
  descriptionPlain: string;
}

interface LeverResults {
  jobs: ReadonlyArray<LeverJob>;
  locations: ReadonlyArray<SelectOptions>;
  teams: ReadonlyArray<SelectOptions>;
  departments: ReadonlyArray<SelectOptions>;
  commitments: ReadonlyArray<SelectOptions>;
}

function convertSetToSelectOptions(set: ReadonlySet<string>) {
  const options = [
    { value: undefined, label: "" },
    ...Array.from(set).map((value) => ({ value, label: value })),
  ];
  return options;
}
function queryJobs() {
  return fetch("https://api.lever.co/v0/postings/pip")
    .then((response) => response.json() as Promise<LeverJob[]>)
    .then((jobs) => {
      const locationSet = new Set<string>();
      const teamSet = new Set<string>();
      const departmentSet = new Set<string>();
      const commitmentSet = new Set<string>();
      jobs.forEach(({ categories }) => {
        locationSet.add(categories.location);
        teamSet.add(categories.team);
        departmentSet.add(categories.department);
        commitmentSet.add(categories.commitment);
      });
      return {
        jobs,
        locations: convertSetToSelectOptions(locationSet),
        teams: convertSetToSelectOptions(teamSet),
        departments: convertSetToSelectOptions(departmentSet),
        commitments: convertSetToSelectOptions(commitmentSet),
      };
    });
}

const Lever: React.FC<{
  title: string;
  showTitle?: boolean;
  department?: "Consulting" | "PGS";
}> = ({ title, showTitle, department }) => {
  const [results, setResults] = useState<LeverResults>();
  const resulttmp = results;
  const [error, setError] = useState<unknown>();
  const [sectionRef, visible] = useFirstVisibleToUser();
  const [commitmentFilter, setCommitmentFilter] = useState<SelectOptions>();
  const [departmentFilter, setDepartmentFilter] = useState<SelectOptions>();
  const [locationFilter, setLocationFilter] = useState<SelectOptions>();
  const [teamFilter, setTeamFilter] = useState<SelectOptions>();
  const [jobs, setJobs] = useState<LeverJob[]>();
  const [LocationInfo, setLocationInfo] = useState({});

  useEffect(() => {
    if (visible) {
      queryJobs()
        .then(setResults)
        .catch((error) => {
          console.error("Failed to get jobs", error);
          setError(error);
        });
    }
  }, [visible]);
  const tmploc = resulttmp?.locations;
  const sortedArray = tmploc?.sort((t1, t2) => {
    const name1 = t1?.label.toLowerCase();
    const name2 = t2?.label.toLowerCase();
    if (name1 > name2) {
      return 1;
    }
    if (name1 < name2) {
      return -1;
    }
    return 0;
  });
  useEffect(() => {
    const teamSet1 = new Set<string>();
    const locationSet1 = new Set<string>();
    const commitmentSet1 = new Set<string>();
    if (results) {
      const jobs = results.jobs.filter((job) => {
        const categories = job.categories;
        if (
          departmentFilter?.value &&
          categories.department == departmentFilter?.value
        ) {
          teamSet1.add(categories.team);
          commitmentSet1.add(categories.commitment);
          locationSet1.add(categories.location);
        }
        if (
          department == categories.department ||
          categories.department == departmentFilter?.value
        ) {
          teamSet1.add(categories.team);
          locationSet1.add(categories.location);
        }
        for (const key in LocationInfo) {
          if (!Object.values(LocationInfo).includes(categories.location)) {
            return false;
          }
        }
        if (department && categories.department !== department) {
          return false;
        }
        if (
          departmentFilter?.value &&
          categories.department !== departmentFilter?.value
        ) {
          return false;
        }

        if (
          commitmentFilter?.value &&
          categories.commitment !== commitmentFilter?.value
        ) {
          return false;
        }
        if (
          locationFilter?.value &&
          categories.location == locationFilter?.value
        ) {
          return false;
        }
        if (teamFilter?.value && categories.team !== teamFilter?.value) {
          return false;
        }
        if (categories.team == teamFilter?.value) {
          commitmentSet1.add(categories.commitment);
          locationSet1.add(categories.location);
        }
        if (categories.commitment == commitmentFilter?.value) {
          locationSet1.add(categories.location);
        }
        return true;
      });
      setJobs(jobs);
      const entries = convertSetToSelectOptions(teamSet1);
      const commitment = convertSetToSelectOptions(commitmentSet1);
      const location = convertSetToSelectOptions(locationSet1);
      const sortedArray = location.sort((t1, t2) => {
        const name1 = t1?.label.toLowerCase();
        const name2 = t2?.label.toLowerCase();
        if (name1 > name2) {
          return 1;
        }
        if (name1 < name2) {
          return -1;
        }
        return 0;
      });
      if (entries.length !== 1) {
        resulttmp["teams"] = entries;
      }
      if (commitment.length !== 1) {
        resulttmp["commitments"] = commitment;
      }
      if (location.length !== 1) {
        resulttmp["locations"] = location;
      }
    }
  }, [
    results,
    department,
    departmentFilter,
    commitmentFilter,
    locationFilter,
    resulttmp,
    teamFilter?.value,
    LocationInfo,
  ]);

  const resetFilters = useCallback(() => {
    setCommitmentFilter(results?.commitments[0]);
    setDepartmentFilter(results?.departments[0]);
    setLocationFilter(results?.locations[0]);
    setTeamFilter(results?.teams[0]);
    setLocationInfo({});
  }, [results]);

  const toggleHandler = (nValues: SelectOptions) => () => {
    setLocationInfo((state) => ({
      ...state,
      [nValues.value]: state[nValues.value] ? null : nValues.value,
    }));
  };
  return (
    <section
      ref={sectionRef}
      data-component="Lever"
      className={classNames(
        "z-10 py-section relative content-grid grid-flow-row-dense",
        results ? "cursor-auto" : "cursor-wait"
      )}
    >
      {showTitle && (
        <h2 className="pb-48px row-start-1 h2 col-start-2 col-span-12 md:col-start-2 md:col-span-12" id="jobs">
          {title}
        </h2>
      )}
      {error ? (
        <>
          <h3 className="h3 col-start-2 col-span-12 md:col-start-8 md:col-span-6 row-start-2">
            Failed to get jobs
          </h3>
        </>
      ) : results ? (
        <>
          <div className="col-start-1 col-span-12 md:col-start-auto md:col-span-4">
            {!department && (
              <Filter
                className="mr-30px mb-20px"
                name="Department"
                options={resulttmp?.departments}
                onChosen={setDepartmentFilter}
                currentValue={departmentFilter}
              />
            )}
            <Filter
              className="mr-30px mb-20px"
              name="Team"
              options={resulttmp?.teams}
              onChosen={setTeamFilter}
              currentValue={teamFilter}
            />
            <Filter
              className="mr-30px mb-20px"
              name="Work Type"
              options={resulttmp?.commitments}
              onChosen={setCommitmentFilter}
              currentValue={commitmentFilter}
            />
            <h4 className="h5 mb-5px">Locations</h4>
            {resulttmp?.locations?.map((n) => {
              if (n.label !== "" && departmentFilter?.value !== "") {
                return (
                  <>
                    <input
                      type="CheckBox"
                      id="Location"
                      name="Location"
                      onChange={toggleHandler(n)}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      checked={LocationInfo[n.value]}
                      style={{ margin: "5px" }}
                    />
                    <label>{n.label}</label>
                    <br />
                  </>
                );
              }
            })}
            <button
              onClick={resetFilters}
              className="h5 py-10px text-light-purple"
            >
              Clear search
            </button>
          </div>
          {jobs?.length ? (
            <div className="col-span-12 md:col-start-auto md:col-span-8 mt-60px lg:mt-0 w-1500px ">
              {jobs.map((j) => (
                <Fragment key={j.id}>
                  <p className="h5 pb-10px">{j.categories.department}</p>
                  <p className="h3 pb-15px">{j.text}</p>
                  <div className="flex items-end justify-between">
                    <div className="flex">
                      <LocaleIcon className="mr-10px" />
                      <h3 className="p3">{j.categories.location}</h3>
                    </div>
                    <a
                      className="flex"
                      href={j.hostedUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Apply <PlusIcon className="mx-10px my-auto" />
                    </a>
                  </div>
                  <div className="my-30px bg-dark-blue h-2px" />
                </Fragment>
              ))}
            </div>
          ) : (
            <div className="h5 col-start-7 col-span-12 md:col-start-auto md:col-span-9 pb-48px flex ">
              <p>No jobs found</p>
            </div>
          )}
        </>
      ) : (
        <p className="h5 col-start-2 col-span-12 md:col-start-8 md:col-span-6">
          Loading latest jobs
        </p>
      )}
    </section>
  );
};

export default Lever;
