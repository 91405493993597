import React, { useCallback } from "react";
import Select, { components } from "react-select";

export interface SelectOptions {
  value: string | undefined;
  label: string;
}

const Filter: React.FC<{
  name: string;
  className?: string;
  options?: ReadonlyArray<SelectOptions>;
  currentValue?: SelectOptions;
  onChosen: (value?: SelectOptions) => void;
}> = ({ name, options, onChosen, className, currentValue }) => {
  const handleChange = useCallback(
    (newValue: SelectOptions | null) => {
      onChosen(newValue || undefined);
    },
    [onChosen]
  );

  return (
    <div className={className}>
      <h4 className="h5 mb-5px">{name}</h4>
      <Select
        value={currentValue}
        aria-label={`Select for ${name}`}
        options={options}
        onChange={handleChange}
        components={{
          Option: ({ children, ...rest }) => (
            <components.Option
              {...rest}
              className="hover:bg-light-grey text-medium-blue"
            >
              {children}
            </components.Option>
          ),
          Placeholder: ({ children, ...rest }) => (
            <components.Placeholder {...rest} className="text-medium-blue">
              {children}
            </components.Placeholder>
          ),
          Control: ({ children, ...rest }) => (
            <components.Control
              {...rest}
              className="px-30px focus:ring-transparent outline-none"
            >
              {children}
            </components.Control>
          ),
          IndicatorSeparator: ({ ...rest }) => (
            <components.IndicatorSeparator
              {...rest}
              className="bg-transparent"
            />
          ),
          DropdownIndicator: ({ children, ...rest }) => (
            <components.DropdownIndicator
              {...rest}
              className="text-medium-blue px-0"
            >
              {children}
            </components.DropdownIndicator>
          ),
          ValueContainer: ({ children, ...rest }) => (
            <components.ValueContainer {...rest} className="px-0">
              {children}
            </components.ValueContainer>
          ),
          SingleValue: ({ children, ...rest }) => (
            <components.SingleValue {...rest} className="text-medium-blue">
              {children}
            </components.SingleValue>
          ),
          Input: ({ children, ...rest }) => (
            <components.Input
              {...rest}
              inputClassName="outline-none border-none shadow-none focus:ring-transparent"
            >
              {children}
            </components.Input>
          ),
        }}
      />
    </div>
  );
};

export default Filter;
