import React from "react";

interface Props {
  className?: string;
}

export const PlusIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 10.633 10.633"
      className={className}
    >
      <g
        id="Groupe_1614"
        data-name="Groupe 1614"
        transform="translate(-1507 20899.5)"
      >
        <g id="Groupe_1938" data-name="Groupe 1938">
          <rect
            id="Rectangle_1933"
            data-name="Rectangle 1933"
            width="10.633"
            height="1.329"
            transform="translate(1507 -20895.07)"
            fill="#c773ab"
          />
          <rect
            id="Rectangle_1934"
            data-name="Rectangle 1934"
            width="10.633"
            height="1.475"
            transform="translate(1511.506 -20888.867) rotate(-90)"
            fill="#c773ab"
          />
        </g>
      </g>
    </svg>
  );
};
