import React from "react";

interface Props {
  className?: string;
}

export const LocaleIcon: React.FC<Props> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.083"
      height="18.144"
      viewBox="0 0 14.083 18.144"
      className={className}
    >
      <path
        id="Tracé_2981"
        data-name="Tracé 2981"
        d="M-79.85,14.9a5.817,5.817,0,0,0-4.663-2.3,5.817,5.817,0,0,0-4.663,2.3,5.879,5.879,0,0,0,0,7.115l4.663,6.086,4.663-6.086A5.879,5.879,0,0,0-79.85,14.9Z"
        transform="translate(91.555 -11.6)"
        fill="none"
        stroke="#c773ab"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
};
